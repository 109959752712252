/*
 * Metro 4 Components Library v4.3.5  (https://metroui.org.ua)
 * Copyright 2012-2020 Sergey Pimenov
 * Built at 14/01/2020 12:35:01
 * Licensed under MIT
 */
.bg-scheme {
  background-color: #ffffff !important;
}
.fg-scheme {
  color: #464646 !important;
}
.bg-scheme-secondary {
  background-color: #f5f5f5 !important;
}
.fg-scheme-secondary {
  color: #464646 !important;
}
.bg-control {
  background-color: #0CA9F2 !important;
}
.fg-control {
  color: #ffffff !important;
}
.bd-default {
  border-color: #0CA9F2 !important;
}
.disabled {
  cursor: default;
}
body {
  font-size: 16px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Ubuntu", "Helvetica Neue", sans-serif;
  color: #464646;
  background-color: #ffffff;
}
hr {
  background-color: #0CA9F2;
}
input[type=text],
input[type=password],
input[type=tel],
input[type=datetime-local],
input[type=email],
input[type=file],
input[type=month],
input[type=number],
input[type=search],
input[type=time],
input[type=url],
input[type=week],
input[type=file],
select,
textarea,
.file,
.input,
.textarea,
.select {
  background-color: #ffffff;
  color: #464646;
  border-color: #0CA9F2 !important;
}
input[type=text]:focus,
input[type=password]:focus,
input[type=tel]:focus,
input[type=datetime-local]:focus,
input[type=email]:focus,
input[type=file]:focus,
input[type=month]:focus,
input[type=number]:focus,
input[type=search]:focus,
input[type=time]:focus,
input[type=url]:focus,
input[type=week]:focus,
input[type=file]:focus,
select:focus,
textarea:focus,
.file:focus,
.input:focus,
.textarea:focus,
.select:focus,
input[type=text].focused,
input[type=password].focused,
input[type=tel].focused,
input[type=datetime-local].focused,
input[type=email].focused,
input[type=file].focused,
input[type=month].focused,
input[type=number].focused,
input[type=search].focused,
input[type=time].focused,
input[type=url].focused,
input[type=week].focused,
input[type=file].focused,
select.focused,
textarea.focused,
.file.focused,
.input.focused,
.textarea.focused,
.select.focused {
  -webkit-box-shadow: 0 0 0 3px rgba(12, 169, 242, 0.45);
          box-shadow: 0 0 0 3px rgba(12, 169, 242, 0.45);
}
.input,
.select,
.textarea,
.file {
  border-style: solid!important;
}
.input .prepend,
.select .prepend,
.textarea .prepend,
.file .prepend {
  background-color: #0CA9F2;
  color: #ffffff;
}
.input input,
.select input,
.textarea input,
.file input,
.input select,
.select select,
.textarea select,
.file select,
.input textarea,
.select textarea,
.textarea textarea,
.file textarea {
  -webkit-box-shadow: none!important;
          box-shadow: none!important;
  border-color: transparent;
}
.file .button {
  background-color: #0CA9F2;
  color: #ffffff;
}
.select .active a {
  background-color: #0CA9F2 !important;
  color: #ffffff !important;
}
.checkbox .check {
  border-color: #0CA9F2;
}
.checkbox .check::before {
  border-color: #464646;
}
.checkbox input[type=checkbox]:checked ~ .check {
  border-color: #0CA9F2;
  background-color: #0CA9F2;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.checkbox input[type=checkbox]:checked ~ .check::before {
  border-color: #ffffff;
}
.checkbox input[type=checkbox]:disabled ~ .check {
  border-color: rgba(12, 169, 242, 0.25);
  background-color: rgba(12, 169, 242, 0.25);
}
.checkbox input[type=checkbox]:disabled ~ .check::before {
  border-color: rgba(12, 169, 242, 0.25);
}
.checkbox input[data-indeterminate=true] ~ .check {
  border-color: #0CA9F2;
  background-color: #0CA9F2;
}
.radio .check {
  border-color: #0CA9F2;
}
.radio input[type=radio]:checked ~ .check {
  border-color: #0CA9F2;
  background-color: #0CA9F2;
}
.radio input[type=radio]:checked ~ .check::before {
  border-color: #ffffff;
  background-color: #ffffff;
}
.radio input[type=radio]:disabled ~ .check {
  border-color: rgba(12, 169, 242, 0.25);
  background-color: rgba(12, 169, 242, 0.25);
}
.radio input[type=radio]:disabled ~ .check::before {
  border-color: rgba(12, 169, 242, 0.25);
  background-color: rgba(12, 169, 242, 0.25);
}
.checkbox .check,
.radio .check {
  color: #ffffff;
}
.switch .check {
  border-color: #0CA9F2;
}
.switch .check::after {
  border-color: #0CA9F2;
  background-color: #0CA9F2;
}
.switch input[type="checkbox"]:checked ~ .check {
  border-color: #0CA9F2;
  background-color: #0CA9F2;
}
.switch input[type="checkbox"]:disabled ~ .check {
  border-color: rgba(12, 169, 242, 0.25);
  background-color: rgba(12, 169, 242, 0.25);
}
.switch input[type="checkbox"]:disabled ~ .check::after {
  border-color: rgba(12, 169, 242, 0.25);
  background-color: rgba(12, 169, 242, 0.25);
}
.switch input[type="checkbox"]:disabled:not(:checked) ~ .check::after {
  opacity: 0.5;
}
.button,
.command-button,
.shortcut,
.tool-button {
  background-color: #0CA9F2;
  color: #ffffff;
}
.button:active,
.command-button:active,
.shortcut:active,
.tool-button:active,
.button.focus,
.command-button.focus,
.shortcut.focus,
.tool-button.focus,
.button:focus,
.command-button:focus,
.shortcut:focus,
.tool-button:focus {
  -webkit-box-shadow: 0 0 0 3px rgba(12, 169, 242, 0.45);
          box-shadow: 0 0 0 3px rgba(12, 169, 242, 0.45);
}
.button:hover,
.command-button:hover,
.shortcut:hover,
.tool-button:hover {
  background-color: #0b98da;
}
.info-button {
  border-color: #0CA9F2;
}
.info-button .button {
  background-color: #0CA9F2;
  color: #ffffff;
}
.info-button .button:hover {
  background-color: #0b98da;
}
.image-button {
  border-color: #0CA9F2;
}
.image-button .icon {
  background-color: #0CA9F2;
  color: #ffffff;
}
.image-button .icon:hover {
  background-color: #0b98da;
}
.breadcrumbs {
  background-color: #ffffff;
  color: #0CA9F2;
}
.breadcrumbs .page-item::before,
.breadcrumbs .page-item::after {
  background-color: #0CA9F2 !important;
}
.breadcrumbs .page-link:hover {
  color: #0b98da;
}
.pagination .page-item:not(.no-link) {
  background-color: #0CA9F2;
  color: #ffffff;
  border: 1px #0CA9F2 solid;
}
.pagination .page-item.active,
.pagination .page-item:hover {
  background-color: #0b98da;
}
.pagination .no-link {
  background-color: transparent;
  color: #0CA9F2;
}
.accordion .frame .heading {
  background-color: #0CA9F2;
  color: #ffffff;
}
.accordion .frame {
  margin: 0;
}
.accordion .frame + .frame {
  border-top: 1px rgba(12, 169, 242, 0.25) solid;
}
.h-menu {
  background-color: #0CA9F2;
  color: #ffffff;
}
.h-menu > li > a.dropdown-toggle::before {
  border-color: #ffffff;
}
.d-menu,
.v-menu {
  background-color: #ffffff !important;
  color: #0CA9F2;
  border: 1px solid #0CA9F2;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.d-menu .dropdown-toggle::before,
.v-menu .dropdown-toggle::before {
  border-color: #0CA9F2 !important;
}
.d-menu li:hover,
.v-menu li:hover {
  background-color: #0CA9F2 !important;
  color: #ffffff !important;
}
.d-menu li:hover .dropdown-toggle::before,
.v-menu li:hover .dropdown-toggle::before {
  border-color: #ffffff !important;
}
.d-menu .divider,
.v-menu .divider {
  background-color: #0CA9F2 !important;
}
.d-menu .menu-title,
.v-menu .menu-title {
  background-color: #0a87c1;
  color: #ffffff;
}
.d-menu .disabled,
.v-menu .disabled {
  color: rgba(12, 169, 242, 0.5) !important;
}
.v-menu .v-menu {
  border: none!important;
}
.t-menu {
  background-color: #0CA9F2;
  color: #ffffff;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.t-menu li a {
  border-color: rgba(255, 255, 255, 0.25) !important;
}
.t-menu .dropdown-toggle::after {
  border-color: transparent transparent #ffffff transparent;
}
.app-bar {
  background-color: #0CA9F2;
  color: #ffffff;
}
.app-bar .dropdown-toggle::before {
  border-color: #ffffff;
}
.app-bar .app-bar-menu li .d-menu,
.app-bar .app-bar-container .d-menu {
  background-color: #0CA9F2 !important;
  color: #ffffff !important;
}
.app-bar .app-bar-menu li .d-menu li:hover,
.app-bar .app-bar-container .d-menu li:hover {
  background-color: rgba(29, 29, 29, 0.1) !important;
}
.app-bar .app-bar-menu li .divider,
.app-bar .app-bar-container .divider {
  background-color: rgba(255, 255, 255, 0.25);
}
[class*=app-bar-expand] .app-bar-menu li .d-menu,
[class*=app-bar-expand] .app-bar-container .d-menu {
  -webkit-box-shadow: none!important;
          box-shadow: none!important;
  border: 1px solid #0CA9F2 !important;
  background-color: #ffffff !important;
  color: #0CA9F2 !important;
}
[class*=app-bar-expand] .app-bar-menu li .d-menu li:hover,
[class*=app-bar-expand] .app-bar-container .d-menu li:hover {
  background-color: #0CA9F2 !important;
  color: #ffffff !important;
}
.calendar {
  border-color: #0CA9F2;
}
.calendar .calendar-header {
  background-color: #0CA9F2;
  color: #ffffff;
}
.calendar .day.today {
  background-color: #0CA9F2;
}
.calendar .day.selected {
  -webkit-box-shadow: 0 0 0 1px #0ca9f2 !important;
          box-shadow: 0 0 0 1px #0ca9f2 !important;
}
.calendar .day.selected::after {
  border-top-color: #0CA9F2;
}
.calendar .calendar-years > .years-list li.active,
.calendar .calendar-months > .months-list li.active {
  background-color: #0CA9F2;
  color: #ffffff;
}
.calendar .calendar-footer {
  border-top-color: rgba(12, 169, 242, 0.25);
}
.calendar .week-days {
  border-top-color: rgba(12, 169, 242, 0.25);
  border-bottom-color: rgba(12, 169, 242, 0.25);
}
[class*=calendar-wide] .calendar-footer {
  border-left-color: rgba(12, 169, 242, 0.25);
}
.countdown {
  line-height: 1.5;
}
.countdown .part {
  color: #464646;
}
.wheel-picker {
  background-color: #ffffff;
  color: #464646;
}
.wheel-picker .date-wrapper,
.wheel-picker .time-wrapper {
  border-color: #0CA9F2;
}
.wheel-picker .select-block {
  background-color: #0CA9F2;
  color: #ffffff;
}
.wheel-picker .select-block ul {
  border-color: rgba(12, 169, 242, 0.25);
}
.wheel-picker .action-block {
  border-top: 1px rgba(12, 169, 242, 0.25) solid;
}
.wheel-picker .action-block .button {
  color: #ffffff;
}
.dialog {
  background-color: #ffffff;
  color: #464646;
  border: 1px solid #0CA9F2;
}
.dialog .dialog-actions {
  border-top-color: #0CA9F2;
}
.keypad .keys {
  background-color: #ffffff;
  border-color: #0CA9F2;
  color: #464646;
}
.keypad .key.service-key {
  background-color: #0a87c1;
}
.hint {
  background-color: #f5f5f5;
  color: #464646;
}
.listview {
  background-color: #ffffff;
  color: #464646;
}
.listview .node:hover {
  background-color: rgba(12, 169, 242, 0.25);
  color: #ffffff;
}
.listview .node.current-select {
  background-color: #0CA9F2;
  color: #ffffff;
  border-color: #0CA9F2 !important;
}
.listview .node-group .node-toggle::before {
  border-color: #464646;
}
.listview.view-content .node + .node {
  border-top-color: #0CA9F2;
}
.master {
  background-color: #f5f5f5;
  color: #464646;
}
.master .controls .next,
.master .controls .prev {
  color: #0CA9F2;
}
.master .controls .next.disabled,
.master .controls .prev.disabled {
  color: rgba(12, 169, 242, 0.25);
}
.master .pages {
  border-color: #0CA9F2;
}
.navview .navview-pane {
  background-color: #f5f5f5;
  color: #464646;
}
.navview .navview-pane .pull-button:hover,
.navview .navview-pane .holder:hover,
.navview .navview-pane .navview-menu li a:hover {
  background-color: rgba(29, 29, 29, 0.1);
}
.navview .navview-pane .pull-button:active,
.navview .navview-pane .holder:active,
.navview .navview-pane .navview-menu li a:active,
.navview .navview-pane .pull-button:focus,
.navview .navview-pane .holder:focus,
.navview .navview-pane .navview-menu li a:focus {
  background-color: #0a87c1;
}
.navview .navview-pane .pull-button:focus,
.navview .navview-pane .holder:focus,
.navview .navview-pane .navview-menu li a:focus {
  border: 2px solid #0a87c1;
}
.navview .navview-pane .dropdown-toggle::after {
  border-color: transparent transparent #ffffff transparent;
}
.navview .navview-content {
  background-color: #ffffff;
  color: #464646;
}
.notify {
  background-color: #f5f5f5;
  color: #464646;
  border: 1px solid #0CA9F2;
}
.panel {
  background-color: #f5f5f5;
  color: #464646;
  border-color: rgba(12, 169, 242, 0.25) !important;
}
.panel .panel-title {
  background-color: #0CA9F2;
  color: #ffffff;
  border-bottom-color: rgba(255, 255, 255, 0.25);
}
.panel .panel-title .dropdown-toggle {
  border-left-color: rgba(255, 255, 255, 0.25);
}
.panel .panel-title .dropdown-toggle::before {
  border-color: #ffffff;
}
.panel .panel-title .icon {
  border-right-color: rgba(255, 255, 255, 0.25);
}
.window {
  border-color: rgba(12, 169, 242, 0.25);
  background-color: #ffffff;
  color: #464646;
}
.window .window-caption {
  background-color: #0CA9F2;
  color: #ffffff;
}
.window .window-content {
  background-color: #f5f5f5;
  color: #464646;
}
.resize-element::after {
  border-bottom-color: #0CA9F2;
}
.popover {
  background-color: #f5f5f5;
  color: #464646;
  border-color: #0CA9F2;
}
.rating .stars li {
  color: rgba(12, 169, 242, 0.25);
}
.rating .stars li.on {
  color: #0CA9F2;
}
.rating:not(.static) .stars:hover li {
  color: #0CA9F2;
}
.rating:not(.static) .stars li:hover ~ li {
  color: rgba(12, 169, 242, 0.25) !important;
}
.sidenav-simple,
.sidenav-counter {
  background-color: #ffffff;
  color: #0CA9F2;
}
.sidenav-simple .active,
.sidenav-counter .active,
.sidenav-simple li.active:hover,
.sidenav-counter li.active:hover {
  background-color: #0CA9F2;
  color: #ffffff;
}
.sidenav-simple li.disabled,
.sidenav-counter li.disabled {
  color: rgba(255, 255, 255, 0.25) !important;
}
.sidenav-m3 {
  background-color: #0CA9F2;
  color: #ffffff;
}
.sidenav-m3 a {
  background-color: #0CA9F2;
}
.sidenav-m3 a:hover {
  background-color: rgba(29, 29, 29, 0.1);
}
.sidenav-m3 li.disabled {
  color: rgba(255, 255, 255, 0.25) !important;
}
.sidenav-m3 li + li {
  border-top-color: #0CA9F2;
}
.sidenav-m3 .d-menu {
  background-color: #0a87c1 !important;
  border-color: #0CA9F2;
  margin-bottom: 4px;
}
.sidenav-m3 .d-menu li {
  border-color: #0CA9F2 !important;
  color: #ffffff !important;
}
.sidenav-m3 .d-menu li.disabled {
  color: rgba(255, 255, 255, 0.25) !important;
}
.sidenav-m3 .dropdown-toggle::before {
  border-color: #ffffff;
}
.slider .backside {
  background-color: rgba(12, 169, 242, 0.25);
}
.slider .buffer {
  background-color: #ffffff;
}
.slider .complete {
  background-color: #0CA9F2;
}
.slider .marker {
  background-color: #464646;
}
.stepper::before {
  background-color: #f5f5f5;
}
.stepper .step {
  background-color: #f5f5f5;
  color: #464646;
}
.stepper .step.complete {
  background-color: #0CA9F2;
  color: #ffffff;
}
.stepper .step.current {
  background-color: #464646;
  color: #ffffff;
}
.streamer .streams {
  background-color: #ffffff;
  color: #464646;
}
.streamer .streamer-action {
  background-color: #0CA9F2;
  color: #ffffff;
}
.streamer .stream {
  border-color: #0CA9F2;
}
.streamer .event-group {
  background-color: #ffffff;
  color: #464646;
}
.streamer .stream-event {
  background-color: #f5f5f5;
  color: #464646;
  border-color: #0CA9F2;
}
.streamer .stream-event:hover {
  border-color: #6ccbf8;
}
.streamer .stream-event.selected {
  -webkit-box-shadow: 0 0 0 1px #0ca9f2 !important;
          box-shadow: 0 0 0 1px #0ca9f2 !important;
}
.streamer .stream-event.selected::before {
  border-color: #464646 !important;
}
.streamer .stream-event.selected::after {
  border-top-color: #0CA9F2;
}
.streamer .stream-event-slide .slide-data .title {
  color: #464646;
}
.streamer .stream-event-slide .slide-data .subtitle,
.streamer .stream-event-slide .slide-data .desc {
  color: #464646;
}
.table thead {
  border-bottom-color: #0CA9F2;
}
.table tfoot {
  border-top-color: #0CA9F2;
  border-bottom-color: #0CA9F2;
}
.table tfoot th {
  color: #ffffff;
}
.table .sortable-column.sort-asc,
.table .sortable-column.sort-desc {
  background-color: #0CA9F2;
  color: #ffffff;
}
.table .sortable-column.sort-asc:after,
.table .sortable-column.sort-desc:after {
  color: #ffffff;
}
.table.striped tbody tr:nth-child(odd) {
  background: #f5f5f5;
}
.table.table-border {
  border-color: #0CA9F2;
}
.table.cell-border th,
.table.cell-border td {
  border-color: #0CA9F2;
}
.tabs {
  background-color: #ffffff;
  color: #ffffff;
  border-color: #0CA9F2;
}
.tabs > ul > li {
  background-color: #f5f5f5;
  color: #464646;
}
.tabs > ul > li:hover {
  background-color: rgba(12, 169, 242, 0.25);
}
.tabs > ul > li.active {
  background-color: #0CA9F2;
  color: #ffffff;
}
.tabs .disabled {
  color: rgba(255, 255, 255, 0.25) !important;
}
.tabs.vertical > ul {
  border-right-color: #0CA9F2;
}
.tabs.vertical > ul > li {
  border-right-color: #0CA9F2;
}
.tabs.vertical > ul > li.active {
  border-color: #0CA9F2;
}
.tabs.vertical.right > ul {
  border-left-color: #0CA9F2;
}
.tabs.vertical.right > ul > li {
  border-left-color: #0CA9F2;
}
.tabs.vertical.right > ul > li.active {
  border-color: #0CA9F2;
}
[class*=tabs-expand] > ul {
  border-bottom-color: #0CA9F2;
}
[class*=tabs-expand] > ul > li {
  border-bottom-color: #0CA9F2;
}
[class*=tabs-expand] > ul > li.active {
  border-color: #0CA9F2;
}
.topbar {
  background-color: #0CA9F2;
  color: #ffffff;
}
.topbar .topbar-brand,
.topbar .topbar-element,
.topbar .topbar-menu {
  color: #ffffff;
}
.treeview {
  background-color: #ffffff;
  color: #464646;
}
.treeview .node-toggle::before {
  border-color: #464646 !important;
}
.treeview li.current > .caption {
  color: #464646;
}
.wizard {
  background-color: #ffffff;
  color: #464646;
  border-color: #0CA9F2;
}
.wizard > section,
.wizard.section {
  border-color: #000000;
}
.wizard > section:nth-child(1),
.wizard.section:nth-child(1) {
  border-color: #0CA9F2;
}
.wizard > section:nth-child(2),
.wizard.section:nth-child(2) {
  border-color: #0b98da;
}
.wizard > section:nth-child(3),
.wizard.section:nth-child(3) {
  border-color: #0a87c1;
}
.wizard > section:nth-child(4),
.wizard.section:nth-child(4) {
  border-color: #0876a9;
}
.wizard > section:nth-child(5),
.wizard.section:nth-child(5) {
  border-color: #076591;
}
.wizard > section:nth-child(6),
.wizard.section:nth-child(6) {
  border-color: #065479;
}
.wizard > section:nth-child(7),
.wizard.section:nth-child(7) {
  border-color: #054360;
}
.wizard > section:nth-child(8),
.wizard.section:nth-child(8) {
  border-color: #043248;
}
.wizard > section:nth-child(9),
.wizard.section:nth-child(9) {
  border-color: #022130;
}
.wizard > section:nth-child(10),
.wizard.section:nth-child(10) {
  border-color: #000000;
}
.wizard > section::before,
.wizard.section::before {
  color: #ffffff;
}
.wizard > section.complete,
.wizard.section.complete {
  border-color: #767676;
}
.wizard > section.complete:nth-child(1),
.wizard.section.complete:nth-child(1) {
  border-color: #f5f5f5;
}
.wizard > section.complete:nth-child(2),
.wizard.section.complete:nth-child(2) {
  border-color: #e8e8e8;
}
.wizard > section.complete:nth-child(3),
.wizard.section.complete:nth-child(3) {
  border-color: #dcdcdc;
}
.wizard > section.complete:nth-child(4),
.wizard.section.complete:nth-child(4) {
  border-color: #cfcfcf;
}
.wizard > section.complete:nth-child(5),
.wizard.section.complete:nth-child(5) {
  border-color: #c2c2c2;
}
.wizard > section.complete:nth-child(6),
.wizard.section.complete:nth-child(6) {
  border-color: #b5b5b5;
}
.wizard > section.complete:nth-child(7),
.wizard.section.complete:nth-child(7) {
  border-color: #a9a9a9;
}
.wizard > section.complete:nth-child(8),
.wizard.section.complete:nth-child(8) {
  border-color: #9c9c9c;
}
.wizard > section.complete:nth-child(9),
.wizard.section.complete:nth-child(9) {
  border-color: #8f8f8f;
}
.wizard > section.complete:nth-child(10),
.wizard.section.complete:nth-child(10) {
  border-color: #767676;
}
.wizard > section.complete::before,
.wizard.section.complete::before {
  color: #464646;
}
.wizard > section.current::before,
.wizard.section.current::before {
  color: #464646;
  background-color: #0CA9F2;
}
.wizard .action-bar button {
  border-color: #0CA9F2;
  color: #ffffff;
}
.ribbon-menu .tabs-holder .static {
  background-color: #0CA9F2;
  color: #ffffff;
}
.ribbon-menu .tabs-holder .static:hover {
  border-color: transparent !important;
}
.ribbon-menu .ribbon-button,
.ribbon-menu .ribbon-tool-button,
.ribbon-menu .ribbon-split,
.ribbon-menu .ribbon-main,
.ribbon-menu .ribbon-icon-button {
  color: #464646;
}
.ribbon-menu .ribbon-button:hover,
.ribbon-menu .ribbon-tool-button:hover,
.ribbon-menu .ribbon-split:hover,
.ribbon-menu .ribbon-main:hover,
.ribbon-menu .ribbon-icon-button:hover {
  border-color: rgba(12, 169, 242, 0.25);
  background-color: rgba(245, 245, 245, 0.25);
}
.ribbon-menu .ribbon-button:active,
.ribbon-menu .ribbon-tool-button:active,
.ribbon-menu .ribbon-split:active,
.ribbon-menu .ribbon-main:active,
.ribbon-menu .ribbon-icon-button:active,
.ribbon-menu .ribbon-button.active,
.ribbon-menu .ribbon-tool-button.active,
.ribbon-menu .ribbon-split.active,
.ribbon-menu .ribbon-main.active,
.ribbon-menu .ribbon-icon-button.active {
  border-color: rgba(12, 169, 242, 0.25);
  background-color: rgba(255, 255, 255, 0.25);
}
.ribbon-menu .ribbon-split-button:hover {
  border-color: rgba(12, 169, 242, 0.25);
}
.ribbon-menu .ribbon-split-button:hover .ribbon-split {
  border-color: rgba(12, 169, 242, 0.25);
}
.ribbon-menu .ribbon-dropdown {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ribbon-menu .ribbon-dropdown li:hover {
  border-color: rgba(12, 169, 242, 0.25);
  background-color: rgba(245, 245, 245, 0.25);
}
.ribbon-menu .ribbon-dropdown .checked::after {
  border-color: rgba(12, 169, 242, 0.25);
  background-color: rgba(245, 245, 245, 0.25);
}
.navview .navview-pane .navview-menu li.active::before {
  background-color: #ffffff;
}
